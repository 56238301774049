/* hind-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Hind';
    font-style: normal;
    font-weight: 300;
    src: url('/layout/fonts/hind-v16-latin-300.eot'); /* IE9 Compat Modes */
    src: url('/layout/fonts/hind-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/layout/fonts/hind-v16-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/layout/fonts/hind-v16-latin-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('/layout/fonts/hind-v16-latin-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('/layout/fonts/hind-v16-latin-300.svg#Hind') format('svg'); /* Legacy iOS */
}
/* hind-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    src: url('/layout/fonts/hind-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('/layout/fonts/hind-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/layout/fonts/hind-v16-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/layout/fonts/hind-v16-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('/layout/fonts/hind-v16-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('/layout/fonts/hind-v16-latin-regular.svg#Hind') format('svg'); /* Legacy iOS */
}
/* hind-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    src: url('/layout/fonts/hind-v16-latin-500.eot'); /* IE9 Compat Modes */
    src: url('/layout/fonts/hind-v16-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/layout/fonts/hind-v16-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/layout/fonts/hind-v16-latin-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('/layout/fonts/hind-v16-latin-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('/layout/fonts/hind-v16-latin-500.svg#Hind') format('svg'); /* Legacy iOS */
}
/* hind-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    src: url('/layout/fonts/hind-v16-latin-600.eot'); /* IE9 Compat Modes */
    src: url('/layout/fonts/hind-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/layout/fonts/hind-v16-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/layout/fonts/hind-v16-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('/layout/fonts/hind-v16-latin-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('/layout/fonts/hind-v16-latin-600.svg#Hind') format('svg'); /* Legacy iOS */
}
/* hind-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Hind';
    font-style: normal;
    font-weight: 700;
    src: url('/layout/fonts/hind-v16-latin-700.eot'); /* IE9 Compat Modes */
    src: url('/layout/fonts/hind-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/layout/fonts/hind-v16-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/layout/fonts/hind-v16-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('/layout/fonts/hind-v16-latin-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('/layout/fonts/hind-v16-latin-700.svg#Hind') format('svg'); /* Legacy iOS */
}


/* roboto-condensed-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('/layout/fonts/roboto-condensed-v27-latin-300.eot'); /* IE9 Compat Modes */
    src: url('/layout/fonts/roboto-condensed-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/layout/fonts/roboto-condensed-v27-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/layout/fonts/roboto-condensed-v27-latin-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('/layout/fonts/roboto-condensed-v27-latin-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('/layout/fonts/roboto-condensed-v27-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}